


//=====================================
//      TRANSITION BROWSER PREFIX
//======================================
@mixin cursor__transition {
    transition: all linear .3s;
    -webkit-transition: all linear .3s;
    -moz-transition: all linear .1s;
    -ms-transition: all linear .2s;
    -o-transition: all linear .3s;
}
@mixin cursor__transition2{
    -moz-transition: all linear .1s; 

} 

// =====================================
//      BACKGROUND IMAGE PROPERTY
//======================================
@mixin background__property {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

// =====================================
//        GRID CONTAINER PROPERTY
//======================================
@mixin grid__container {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 100%;
}

// =====================================
//         FLEX START PROPERTY
//======================================
@mixin flex__start {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

// =====================================
//         FLEX CENTER PROPERTY
//======================================
@mixin flex__center {
    display: flex;
    align-items: center;
    justify-content: center;
}

// =====================================
//     FLEX CENTER START PROPERTY
//======================================
@mixin flex__center__start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

// =====================================
//     FLEX CENTER END PROPERTY
//======================================
@mixin flex__center__end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

// =====================================
//     FLEX CENTER BETWEEN PROPERTY
//======================================
@mixin flex__center__between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

// =====================================
//     FLEX START BETWEEN PROPERTY
//======================================
@mixin flex__start__between {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}



