/*========================================
            USER-FORM PAGE STYLE
=========================================*/
.user-form-part {
  padding-top: 30px;
}

.user-form-logo {
  text-align: center;
  margin-bottom: 25px;
}

.user-form-logo img {
  width: 200px;
}

.user-form-card {
  padding: 30px;
  border-radius: 8px;
  margin-bottom: 20px;
  background: var(--white);
  border: 1px solid var(--border);
}

.user-form-title {
  text-align: center;
  margin-bottom: 30px;
}

.user-form-title h2 {
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 5px;
  color: var(--secondary);
}

.user-form-title p {
  text-transform: capitalize;
}

.user-form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.user-form-social {
  width: 100%;
}

.user-form-social li {
  margin-bottom: 20px;
}

.user-form-social li a {
  width: 100%;
  font-size: 15px;
  padding: 10px 0px;
  border-radius: 8px;
  letter-spacing: 0.3px;
  text-align: center;
  text-transform: capitalize;
  color: var(--white);
  background: var(--primary);
}

.user-form-social li a i {
  font-size: 16px;
  margin-right: 10px;
}

.user-form-social li:last-child {
  margin-bottom: 0px;
}

.user-form-social .facebook {
  background: var(--facebook);
}

.user-form-social .twitter {
  background: var(--twitter);
}

.user-form-social .google {
  background: var(--google);
}

.user-form-social .instagram {
  background: var(--instagram);
}

.user-form-divider {
  width: 1px;
  height: 240px;
  margin: 0px 50px;
  background: var(--border);
  position: relative;
  z-index: 1;
}

.user-form-divider p {
  width: 40px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  margin: 0 auto;
  font-style: italic;
  line-height: 38px;
  border-radius: 50%;
  text-align: center;
  color: var(--gray);
  background: var(--white);
  border: 1px solid var(--border);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.user-form {
  width: 100%;
}

.user-form .form-group {
  margin-bottom: 20px;
}

.form-button {
  text-align: center;
}

.form-button button {
  width: 100%;
  height: 45px;
  font-size: 14px;
  font-weight: 500;
  line-height: 45px;
  border-radius: 8px;
  letter-spacing: 0.3px;
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
  background: var(--primary);
}
.form-button button:hover{
  background-color: var(--quaternary);
}

.form-button p {
  font-size: 15px;
  margin-top: 12px;
  text-transform: capitalize;
}

.form-button p a {
  font-weight: 500;
  margin-left: 5px;
  color: var(--primary);
}

.form-button p a:hover {
  text-decoration: underline;
}

.user-form-remind {
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  background: var(--white);
  border: 1px solid var(--border);
}

.user-form-remind p {
  font-size: 15px;
  text-transform: capitalize;
}

.user-form-remind p a {
  font-weight: 500;
  margin-left: 5px;
  color: var(--primary);
}

.user-form-remind p a:hover {
  text-decoration: underline;
}

.user-form-footer {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 40px;
}

.user-form-footer p {
  font-size: 14px;
  color: var(--gray);
}

.user-form-footer p a {
  color: var(--primary);
  transition: all linear .3s;
  -webkit-transition: all linear .3s;
  -moz-transition: all linear .3s;
  -ms-transition: all linear .3s;
  -o-transition: all linear .3s;
}

.user-form-footer p a:hover {
  text-decoration: underline;
}

@media (max-width: 575px) {
  .user-form-card {
    padding: 20px;
  }
}

@media (max-width: 767px) {
  .user-form-group {
    display: inherit;
  }
  .user-form-divider {
    width: 100%;
    height: 1px;
    margin: 50px 0px;
  }
}
