


.btn {
    border: none;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 14px 32px;
    border-radius: 6px;
    border: 2px solid var(--quaternary);

    &:focus {
        box-shadow: none !important;
    }

    i {
        margin-right: 5px;
    }
}


.btn-inline {
    color: var(--white);
    background: var(--quaternary);

    &:hover {
        color: var(--quaternary);
        background: var(--white);
    }
}


.btn-outline {
    color: var(--quaternary);
    background: var(--white);

    &:hover {
        color: var(--white);
        background: var(--quaternary);
    }
}