







.backtop {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 2;
    width: 48px;
    height: 48px;
    font-size: 15px;
    color: var(--white);
    line-height: 48px;
    text-align: center;
    display: none;
    border-radius: 50%;
    background: var(--primary);
    box-shadow: 0px 0px 15px 3px rgba(71, 254, 126, 0.5);
    animation: mahmud 2s infinite;
    @include cursor__transition;

    &:hover {
        color: var(--white);
    }
}

@keyframes mahmud {
    0% {
        margin-bottom: 0px;
    }

    50% {
        margin-bottom: 15px;
    }
}

@media (max-width: 575px) {
    .backtop {
        width: 42px;
        height: 42px;
        line-height: 42px;
        font-size: 14px;
    }
}

@media (max-width: 991px) {
    .backtop {
        bottom: 55px;
    }
}