
.row-profile { 
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
   align-content: space-evenly; 
}


.profile-card {
    padding: 25px;
    border-radius: 8px;
    margin-bottom: 25px;
    background: var(--chalk);
    border: 1px solid var(--border);
    position: relative;
    cursor: pointer;

    &:hover {
        ul {
            visibility: visible;
            opacity: 1;
        }
    }

    h6 {
        color: var(--text);
        margin-bottom: 8px;
        text-transform: capitalize;
    }

    p {
        text-transform: capitalize;
        color: var(--text);
       
        font-weight: 600;
        margin-bottom: 8px;
    }

    label {
        word-wrap: break-word;
        text-overflow: ellipsis;
        background-color: rgba($color: #000000, $alpha: 0.1); 
        border-radius: 9px;
         margin-bottom: 8px;
        padding: 1px 10px;
        margin-left: 3%;
        color: black; 
        font-weight: 200;
        text-transform: uppercase;
       
    }

    ul {
        @include flex__center;
        flex-direction: column;
        position: absolute;
        top: 50%;
        right: 15px;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-50%);
        @include cursor__transition;

        li {
            margin: 5px 0px;

            button {
                width: 30px;
                height: 32px;
                line-height: 32px;
                border-radius: 6px;
                text-align: center;
                display: inline-block;
                background: var(--white);
                text-shadow: var(--primary-tshadow);
                box-shadow: var(--primary-bshadow);
                @include cursor__transition;
            }
        }
    
        .edit {
            color: var(--green);

            &:hover {
                color: var(--white);
                background: var(--green);
            }
        }
    
        .trash {
            color: var(--red);

            &:hover {
                color: var(--white);
                background: var(--red);
            }
        }
    }
}

.profile-card.active {
    background: var(--green-chalk);
    border-color: var(--primary);
}

@media  (max-width: 991px) {
    .profile-card {
        margin-bottom: 15px;
    }
    .span-profile {
  
     
    label {
        
        margin: auto;
        margin-right: 5px;

        
    }
         a {
        margin-bottom: 50px;
       
        color: var(--text);
        
    }
    span {
       
        margin-bottom: 10px;
        font-size: 14px;
        
        color: var(--text);
        
    }
 
}

}	


@media  (max-width: 765px) {
    .col-lg-4.col-profile{

        width: 100% !important;
    
    }
      
    .row-profile { 
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
       align-content: flex-start; 
       
     
        
        .profile-card {
           
            width: 250px; 
            margin-bottom: 30px;
            

            p {
                width: 200px;
                margin: auto;
            }

            input {
                width: 200px;
                margin: auto;
                margin-top:15px ;
            }
        }

         
    }
    .span-profile {
        display: block;
       
        background: var(--chalk);
        border: 1px solid var(--border);
        width: 250px;
        padding: 25px;
        margin-top: 15px;
        margin-bottom: 25px;
        border-radius: 8px;
     
        span {
           
            margin-bottom: 10px;
            font-size: 14px;
            
            color: var(--text);
            
        }
     
    }
    

}