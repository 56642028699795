


//========================================
//          PRODUCT VIEW STYLE
//========================================
.product-view {
    max-width: 1000px;
    border-radius: 8px;
    background: var(--white);
}

.view-gallery {
    margin: 30px;
    position: relative;
}

.view-label-group {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.view-label {
    font-size: 15px;
    padding: 6px 10px;
    margin-bottom: 6px;
    line-height: 13px;
    border-radius: 3px;
    text-align: center;
    text-transform: capitalize;
    color: var(--white);

    &:last-child {
        margin-bottom: 0px;
    }
}

.view-label.off {
    background: var(--red);
}

.view-label.new {
    background: var(--green);
}

.view-label.sale {
    background: var(--orange);
}

.view-label.feat {
    background: var(--purple);
}

.view-label.rate {
    background: var(--yellow);
}

.view-details {
    margin: 30px;
}

.view-name {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 5px;
    text-transform: capitalize;

    a {
        color: var(--heading);

        &:hover {
            color: var(--primary);
        }
    }
}

.view-meta {
    margin-bottom: 12px;
    @include flex__center__start;

    p {
        font-size: 13px;
        margin-right: 20px;
        text-transform: uppercase;
        color: var(--placeholder);
    }

    span,
    a {
        margin-left: 5px;
        color: var(--placeholder);
    }

    a {
        &:hover {
            text-decoration: underline;
            color: var(--primary);
        }
    }
}

.view-rating {
    @include flex__center__start;
    margin-bottom: 15px;

    i,
    a {
        font-size: 15px;
        margin-right: 3px;
        color: var(--gray);
    }

    a {
        margin-left: 8px;
        white-space: nowrap;
        text-transform: capitalize;
        @include cursor__transition;

        &:hover {
            color: var(--primary);
            text-decoration: underline;
        }
    }

    .active {
        color: var(--yellow);
    }
}


.view-price {
    margin-bottom: 20px;

    del {
        color: var(--red);
        margin-right: 25px;
    }

    span {
        color: var(--primary);

        small {
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
        }
    }
}

.view-desc {
    margin-bottom: 25px;
}

.view-list-group {
    @include flex__center;
    justify-content: flex-start;
    margin-bottom: 25px;

    &:last-child {
        margin-bottom: 0px;
    }
}

.view-list-title {
    font-weight: 500;
    margin-right: 15px;
    color: var(--heading);
    text-transform: capitalize;
}

.view-tag-list {
    @include flex__center;

    li {
        margin-right: 8px;

        a {
            font-size: 14px;
            line-height: 12px;
            padding: 8px 10px;
            border-radius: 5px;
            letter-spacing: 0.3px;
            text-transform: capitalize;
            color: var(--text);
            background: var(--chalk);
            @include cursor__transition;

            &:hover {
                color: var(--white);
                background: var(--primary);
            }
        }
    }
}

.view-share-list {
    @include flex__center;

    li {
        margin-right: 8px;

        a {
            width: 35px;
            height: 35px;
            font-size: 16px;
            line-height: 35px;
            border-radius: 50%;
            text-align: center;
            color: var(--text);
            background: var(--chalk);
            @include cursor__transition;

            &:hover {
                color: var(--white);
                background: var(--primary);
            }
        }
    }
}

.view-add-group {
    margin: 45px 0px 15px;

    .product-add,
    .action-input {
        height: 46px;
        line-height: 46px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        color: var(--white);
        background: var(--primary);
    }
}


.view-action-group {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    position: relative;

    a {
        padding: 10px 0px;
        border-radius: 8px;
        color: var(--text);
        background: var(--border);
        @include flex__center;
        @include cursor__transition;

        i {
            font-size: 16px;
            margin-right: 8px;
        }
    
        span {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }
    }
}

.view-wish.active {
    color: var(--white);
    background: var(--primary);
}


.preview-slider {
    margin-bottom: 20px;

    li {
        img {
            width: 100%;
            border-radius: 8px;
        }
    }
}


.thumb-slider {
    li {
        margin: 0px 10px;
        cursor: pointer;

        img {
            width: 100%;
            border-radius: 8px;
            border: 1px solid var(--border);
        }
    }

    .slick-center {
        img {
            border: 1px solid var(--primary);
        }
    }
}


@media (max-width: 500px) {
    .view-gallery,
    .view-details {
        margin: 25px;
    }

    .view-list-group {
        align-items: flex-start;
        flex-direction: column;
    }

    .view-list-title {
        margin: 0px 0px 10px;
    }
}

@media (max-width: 767px) {
    .product-view {
        max-width: 480px;
    }
}