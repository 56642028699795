


.backdrop {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 4;
    width: 1000%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: none;
}