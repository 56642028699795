



.section {
    margin-bottom: 50px;
    background-size: cover !important;
    padding: 0px 0px;
    position: relative;
    
    &::before {
        position: absolute;
        content: "";
        left: 0px;
        width: 100%;
        height: 8%;
        background: linear-gradient(to right, rgba(53, 102, 89, 1), rgba(118, 234, 189, 1));
        z-index: -1;
    }
}

.inner-section {
    margin-bottom: 100px;
}

.section-heading {
    text-align: center;
    margin-bottom: 40px;
    h2{
        color: #fff;
        font-size: 30px;
        font-weight: 700;
        margin-bottom: -5px;
    }
    
}

   
   
    

   
.section-btn-50 {
    text-align: center;
    margin-top: 50px;
}

.section-btn-25 {
    text-align: center;
    margin-top: 25px;
}


@media (max-width: 767px) {
    
    .section {
        margin-bottom: 55px;
    }

    .inner-section {
        margin-bottom: 60px;
    }

    .section-heading {
        margin-bottom: 30px;

        h2 {
            font-size: 28px;
            line-height: 36px;
        }
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .section {
        margin-bottom: 75px;
    }

    .inner-section {
        margin-bottom: 80px;
    }
}