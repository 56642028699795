/*========================================
            ABOUT PAGE STYLE
=========================================*/
body {
  background: var(--white);
}

.about-content h2 {
  margin-bottom: 25px;
}

.about-content p {
  margin-bottom: 40px;
}

.about-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.about-list li {
  padding-left: 30px;
  border-left: 1px solid var(--border);
}

.about-list li:first-child {
  border-left: 0px;
  padding-left: 0px;
}

.about-list li h3 {
  margin-bottom: 5px;
}

.about-list li h6 {
  font-weight: 400;
  color: var(--text);
  text-transform: capitalize;
}

.about-img {
  display: -ms-grid;
  display: grid;
  grid-gap: 20px;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  margin-left: 50px;
}

.about-img img {
  width: 100%;
  border-radius: 8px;
}

.about-testimonial {
  background: var(--white);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 100px 0px 70px;
}

.testi-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-bottom: 30px;
}

.testi-img {
  margin-right: 80px;
  border-radius: 50%;
  -webkit-box-shadow: var(--primary-bshadow);
          box-shadow: var(--primary-bshadow);
}

.testi-img img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 10px solid var(--white);
}

.testi-quote {
  width: 600px;
}

.testi-quote i {
  font-size: 50px;
  color: var(--primary);
  display: inline-block;
  margin-bottom: 30px;
}

.testi-quote p {
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 30px;
}

.testi-quote h4 {
  text-transform: capitalize;
  margin-bottom: 5px;
}

.testi-quote h6 {
  font-weight: 400;
  color: var(--text);
}

.about-choose {
  margin-bottom: 75px;
}

.choose-card {
  margin: 25px 15px;
}

.about-brand {
  background: var(--green-chalk);
  padding: 90px 0px 100px;
}

.brand-slider li {
  margin: 0px 10px;
  padding: 20px 0px;
  border-radius: 8px;
  background: var(--white);
}

.brand-slider li img {
  width: 100%;
}

.team-slider li {
  margin: 0px 12px;
}

.team-slider .dandik,
.team-slider .bamdik {
  top: 40%;
}

@media (max-width: 575px) {
  .about-list li {
    padding-left: 15px;
  }
}

@media (max-width: 767px) {
  .testi-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .testi-img {
    margin-right: 0px;
  }
  .testi-quote {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .testi-img {
    margin-right: 50px;
  }
  .testi-quote {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .about-list {
    margin-bottom: 30px;
  }
  .about-img {
    margin-left: 0px;
  }
}
