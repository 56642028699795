
.chat {
position: fixed;
bottom: 5px;
right: 5px;
width: 50px;
height: 50px;
background: #fff;
border: 1px solid #ddd;
border-radius: 50%;
box-shadow: 0 0 10px rgba(0,0,0,0.1);
z-index: 5;
transition: 0.3s;
color: #32e6b5;



&:hover {
    border-radius: 15px;
  display: block;
  padding: 10px;
  width: 250px;
  height: 60px;
  text-align: left;
 

  &::after {
    content: "Solicitar asistencia de un agente";
    position: absolute;
    top: 10%;
    left: 20%;
    height: 15px;
    color: #666;
  
   
  }
}
 

}

.open-chat {
    position: fixed;
    bottom: 63vh;
    z-index: 7;
    right: 2px;
    width: 50px;
    height: 50px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 15px;
    color:#fff;

   &::after {
    content: "\2573";
    position: absolute;
    top: 22%;
    left: 35%;
    height: 15px;
    color: #f35;
    font-weight: bolder;
     
    &:hover{
        color: #32e6b5;

    }
}
}


.chat-window{
    position: fixed;
    bottom: 1%;
    right: 1%;
    z-index: 6;
    width: min(350px , 97%); 
    
    height: 65vh;
    border: 1px solid #ccc;
    background: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    border-radius: 5px;
    overflow: hidden;
    transition: all .3s ease-in-out;
   
  
    .chat-header{
        position: relative;
        padding: 10px 15px;
        background: #f5f5f5;
        border-bottom: 1px solid #eee;
        
        .chat-header-title{
            display: inline-block;
            margin: 0;
            padding: 0;
            line-height: 1;
            font-size: 15px;
            font-weight: 800;
        }
      
    }
     
    .chat-body{
        padding: 10px 15px;
        height: calc(92% - 70px);
        overflow-y: auto;
      .chat-body__messages{
        
        .chat-message{
            margin-bottom: 10px;
            width:  100%;
            display: flex;
            flex-direction: column;

             
            .chat-message-flex{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                        .chat-message-content{
                            
                            float: left;
                            align-items: flex-end;
                            background: #32e6b5bd;
                        }
                    }
                    
                
            

         
            .chat-message-content{
                width: auto;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                padding: 10px 15px;
                border-radius: 5px;
                background: #27b38e9d;
                font-size: 14px;
                line-height: 1.5;
                
            }
           
        }
        
        .chat-reciever{
    

            .admin-message{
                width: 250px;
                flex-wrap: nowrap;
                display: flex;
                align-items: center;
                .admin-message-content{
                    width: auto;
                    align-items: flex-start;
                    padding: 10px 15px;
                    border-radius: 2px ;
                    background: #ff666670;
                    font-size: 14px;
                    line-height: 1.5;                }


            }
 .chat-message-flex{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
            .chat-message-content{
                
                float: right;
                align-items: flex-end;
                background: #f5f5f5;
            }
        }
        }
    }
}
    .chat-footer{
        padding: 2vh 1vw;
        background: #999;
        border-top: 1px solid #eee;

        .chat-textarea{
            width: min(80% , 70%);
            height: 40px;
            padding: 5px 10px;
            border: none;
            border-radius: 3px;
            background: #fff;
            resize: none;
            &:disabled{
                background: #ccc;
                color: #999;
                cursor: default;
            }
        }

        .chat-submit{
            display: inline-block;
            margin-left: 5px;
            padding: 5px 10px;
            border: none;
            border-radius: 3px;
            background: #ddd;
            color: #666;
            font-size: 13px;
            cursor: pointer;
            &:hover{
                background: #ccc;
            }
            &:disabled{
                background: #ccc;
                color: #999;
                cursor: default;
            }
        }
        
      
  
    }
}
