



.menu-badge {
    font-size: 10px;
    padding: 0px 5px;
    margin-left: 5px;
    line-height: 18px;
    border-radius: 3px;
    letter-spacing: 0.3px;
    display: inline-block;
    text-transform: uppercase;
    color: var(--white);
    background: var(--red);
    text-shadow: var(--primary-tshadow);
}