


//========================================
//        MOBILE MENU LAYOUT STYLE
//========================================
.mobile-menu {
    position: sticky;
    top: 75px;
    left: 0px;
    width: 100%;
    z-index: 3;
    
    border-radius: 0px 0px 5px 5px;
    
    justify-content: space-between;
    display: none;
    // position: fixed;
    // bottom: 0px;
    // left: 0px;
    // width: 100%;
    // z-index: 3;
    // background: var(--white);
    // border-radius: 10px 10px 0px 0px;
    // box-shadow: 0px -5px 15px 0px rgba(0, 0, 0, 0.1);
    // @include flex__center;
    // justify-content: space-between;
    // display: none;
    
    a,
    button {
        @include flex__center;
        flex-direction: column;
        width: 80px;
        padding: 8px 0px;
        border-radius: 8px;
        position: relative;

        &:hover {
            background: var(--chalk);

            i {
                color: var(--primary);
            }

            span {
                color: var(--primary);
            }
        }

        i {
            font-size: 15px;
            margin-bottom: 5px;
            color: var(--white);
            text-shadow: 2px 3px 8px rgba(0, 0, 0, 0.1);
        }
        img 
        {
            width: 30px;
            height: 30px;
            margin-bottom: 5px;
        }

        span {
            font-size: 10px;
            line-height: 12px;
            color: var(--text);
            text-transform: uppercase;
        }

        sup {
            position: absolute;
            top: -5px;
            left: 75%;
            width: 24px;
            height: 24px;
            font-size: 12px;
            line-height: 20px;
            border-radius: 50%;
            text-align: center;
            transform: translateX(-50%);
            color: var(--white);
            background: var(--quaternary);
            border: 2px solid var(--quaternary);
            text-shadow: var(--primary-tshadow);
        }
    
        .fas fa-shopping-basket {
            font-size: 18px;
        }
    }
}

.mobile-menu.sticky{ 
    position: fixed;
    top: 75px;
    left: 0px;
    width: 100%;
    z-index: 3;
   
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
    
 
    
}

@media (max-width: 991px) {
    .mobile-menu {
        display: flex;
    }
}
