


//========================================
//     PRODUCT STANDARD ELEMENT STYLE
//========================================
.product-standard {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 25px;
    padding: 15px 15px;
    border-radius: 8px;
    background: var(--white);
    border: 1px solid var(--border);
    @include flex__center__start;
    @include cursor__transition;

    &:hover {
        border-color: var(--primary);
        box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.1);
        
        .standard-widget {
            opacity: 1;
            bottom: 15px;
        }

        .standard-add {
            color: var(--white);
            background: var(--primary);
        }
    }
}

.standard-media {
    position: relative;
}

.standard-label-group {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 1;
}

.standard-label {
    font-size: 14px;
    padding: 5px 8px;
    line-height: 13px;
    border-radius: 3px;
    text-transform: capitalize;
    color: var(--white);
}

.standard-label.off {
    background: var(--red);
}

.standard-label.new {
    background: var(--green);
}

.standard-label.sale {
    background: var(--orange);
}

.standard-label.feat {
    background: var(--purple);
}

.standard-label.rate {
    background: var(--yellow);
}

.standard-image {
    img {
        width: 220px;
    }
}

.standard-widget {
    @include flex__center;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    opacity: 0;
    @include cursor__transition;

    a {
        width: 40px;
        height: 40px;
        font-size: 15px;
        margin: 0px 6px;
        line-height: 40px;
        text-align: center;
        border-radius: 8px;
        color: var(--white);
        background: var(--primary);
        text-shadow: var(-primary-tshadow);
        box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.12);
    }
}

.standard-content {
    width: 100%;
    margin-left: 30px;
    padding-left: 30px;
    border-left: 1px solid var(--border);
}

.standard-name {
    margin-bottom: 5px;
    text-transform: capitalize;

    a {
        color: var(--sub-heading);
        @include cursor__transition;

        &:hover {
            color: var(--primary);
        }
    }
}

.standard-price {
    @include flex__center__start;
    align-items: baseline;
    margin-bottom: 5px;

    del {
        color: var(--red);
        margin-right: 12px;
    }

    span {
        color: var(--primary);
        white-space: nowrap;

        small {
            font-weight: 400;
        }
    }
}

.standard-rating {
    @include flex__center__start;
    margin-bottom: 10px;

    i,
    a {
        font-size: 14px;
        margin-right: 3px;
        color: var(--gray);
    }

    a {
        white-space: nowrap;
        @include cursor__transition;

        &:hover {
            color: var(--primary);
            text-decoration: underline;
        }
    }

    .active {
        color: var(--yellow);
    }
}

.standard-desc {
    margin-bottom: 20px;
}

.standard-action-group {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));

    .product-add,
    .action-input {
        padding: 10px 0px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: var(--white);
        background: var(--primary);
    }

    .product-add {
        font-size: 14px;
        font-weight: 500;
    }
}


.standard-wish {
    padding: 10px 0px;
    border-radius: 8px;
    @include flex__center;
    @include cursor__transition;

    i {
        font-size: 16px;
        margin-right: 6px;
    }

    span {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }
}

.standard-wish {
    color: var(--primary);
    background: var(--border);
}

.standard-wish.active {
    color: var(--white);
    background: var(--primary);
}

.standard-action {
    @include flex__center;
    display: none;

    button,
    a {
        i {
            width: 35px;
            height: 35px;
            font-size: 15px;
            line-height: 35px;
            border-radius: 5px;
            text-align: center;
            display: inline-block;
            color: var(--text);
            background: var(--border);
            text-shadow: var(--primary-tshadow);
            @include cursor__transition;

            &:hover {
                color: var(--white);
                background: var(--primary);
            }
        }
    }

    input {
        width: 100%;
        height: 45px;
        margin: 0px 8px;
        font-size: 15px;
        line-height: 14px;
        border-radius: 5px;
        letter-spacing: 0.3px;
        text-align: center;
        color: var(--white);
        background: var(--primary);
        text-shadow: var(--primary-tshadow);
    }
}


//========================================
//    RESPONSIVE PRODUCT STANDARD STYLE
//========================================
@media (max-width: 575px) {
    .product-standard {
        padding: 10px 10px;
    }

    .standard-image {
        img {
            width: 130px;
        }
    }

    .standard-content {
        margin-left: 10px;
        padding-left: 10px;
    }

    .standard-name {
        font-size: 16px;
        margin-bottom: 3px;
    }

    .standard-price {
        font-size: 15px;
        margin-bottom: 3px;
    }

    .standard-rating {
        margin-bottom: 6px;
    }

    .standard-desc {
        display: none;
    }

    .standard-action-group {
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    }

    .product-add,
    .standard-wish {
        padding: 8px 0px;

        i {
            display: none;
        }
    }

    .standard-action {
        input {
            height: 38px;
        }
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .standard-image {
        img {
            width: 150px;
        }
    }

    .standard-content {
        margin-left: 10px;
        padding-left: 10px;
    }

    .standard-name {
        font-size: 18px;
    }

    .standard-price {
        font-size: 16px;
    }

    .standard-desc {
        display: none;
    }

    .standard-action-group {
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    }

    .product-add,
    .standard-wish {
        padding: 8px 0px;

        i {
            display: none;
        }
    }

    .standard-action {
        input {
            height: 38px;
        }
    }
}
