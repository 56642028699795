/*========================================
            ORDERLIST PAGE STYLE
=========================================*/
.orderlist-filter {
  padding: 20px 25px;
  margin-bottom: 30px;
  border-radius: 8px;
  background: var(--white);
  border: 1px solid var(--border);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.orderlist-filter h5 {
  text-transform: capitalize;
}

.filter-short {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.filter-short .form-label {
  font-size: 18px;
  margin: 0px 8px 0px 0px;
  color: var(--heading);
}

.filter-short .form-select {
  width: 150px;
  background: var(--chalk);
}

.orderlist {
  padding: 0px 30px;
  border-radius: 8px;
  margin-bottom: 30px;
  background: var(--white);
  border: 1px solid var(--border);
  transition: all linear .3s;
  -webkit-transition: all linear .3s;
  -moz-transition: all linear .3s;
  -ms-transition: all linear .3s;
  -o-transition: all linear .3s;
}

.orderlist-head {
  padding: 25px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: pointer;
}

.orderlist-head h5 {
  color: var(--primary);
  text-transform: capitalize;
}

.orderlist-details {
  padding: 25px;
  border-radius: 8px;
  background: var(--chalk);
  margin-bottom: 25px;
}

.orderlist-details li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 20px;
}

.orderlist-details li:last-child {
  margin-bottom: 0px;
}

.orderlist-details li h6 {
  line-height: 26px;
  white-space: nowrap;
  text-transform: capitalize;
}

.orderlist-details li h6 small {
  font-size: 14px;
  font-weight: 400;
  margin-left: 3px;
}

.orderlist-details li p {
  width: 250px;
  text-align: right;
}

.orderlist-deliver {
  height: 215px;
  padding: 25px;
  border-radius: 8px;
  margin-bottom: 25px;
  background: var(--chalk);
}

.orderlist-deliver h6 {
  margin-bottom: 15px;
  white-space: nowrap;
  text-transform: capitalize;
}

.orderlist-deliver p {
  text-transform: capitalize;
}

.table-list {
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 30px;
  background: var(--chalk);
}

@media (max-width: 575px) {
  .orderlist-filter {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .orderlist-filter h5 {
    margin-bottom: 15px;
  }
  .orderlist {
    padding: 0px 15px;
  }
  .orderlist-head {
    padding: 15px 0px;
  }
}
