


//========================================
//      SINGLE BANNER LAYOUT STYLE
//========================================
.single-banner {
    background-size: cover !important;
    padding: 100px 0px;
    text-align: center;
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        content: "";
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: linear-gradient(to left, rgba(20, 102, 71, 1), rgba(39, 179, 141, 1));
        z-index: -1;
    }

    h2 {
        font-size: 38px;
        margin-bottom: 15px;
        color: var(--white);
        text-transform: uppercase;
    }
}

@media (max-width: 767px) {
    .single-banner {
        padding: 60px 0px;
        
        h2 {
            font-size: 32px;
            margin-bottom: 12px;
        }
    }
}