/*========================================
            PRIVACY PAGE STYLE
=========================================*/
.scrollspy {
  position: relative;
}

.scrollspy-content {
  margin-bottom: 50px;
}

.scrollspy-content:last-child {
  margin-bottom: 0px;
}

.scrollspy-content h3 {
  margin-bottom: 20px;
  padding-left: 50px;
  position: relative;
}

.scrollspy-content h3::before {
  position: absolute;
  content: "\2047";
  top: 50%;
  left: 0px;
  width: 35px;
  height: 35px;
  font-size: 16px;
  font-family: icofont;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  color: var(--white);
  background: var(--primary);
}
