/*========================================
            PROFILE PAGE STYLE
=========================================*/
.profile-image {
  text-align: center;
}

.profile-image a {
  border-radius: 50%;
  border: 2px solid var(--primary);
}

.profile-image a img {
  width: 80px;
  border-radius: 50%;
  border: 3px solid var(--white);
}

.profile-btn {
  margin-top: 33px;
}

.profile-btn a {
  width: 100%;
  height: 45px;
  line-height: 45px;
  border-radius: 8px;
  text-align: center;
  text-transform: capitalize;
  color: var(--white);
  background: var(--primary);
  transition: all linear .3s;
  -webkit-transition: all linear .3s;
  -moz-transition: all linear .3s;
  -ms-transition: all linear .3s;
  -o-transition: all linear .3s;
}

.profile-btn a:hover {
  background: var(--green);
}



.col-lg-4 {
  flex: 0 0 auto;
  width: 33.3333333333%;
}
@media (max-width: 991px) {
  .profile-btn {
    margin-top: 5px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .profile-image {
    margin-bottom: 20px;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

