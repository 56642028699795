


.news-part {
    background-size: cover !important;
    padding: 70px 0px;
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        content: "";
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(53, 102, 89, 1), rgba(118, 234, 189, 1));
        z-index: -1;
    }
}

.news-text {
    h2 {
        font-size: 35px;
        color: var(--white);
        margin-bottom: 5px;
    }

    p {
        font-size: 18px;
        font-weight: 300;
        color: var(--white);
    }
}

.news-form {
    width: 100%;
    padding: 6px;
    border-radius: 8px;
    background: var(--white);
    @include flex__center;

    input {
        width: 100%;
        height: 50px;
        padding: 0px 20px;
        line-height: 50px;
    }

    button {
        span {
            width: 180px;
            font-size: 14px;
            font-weight: 500;
            padding: 12px 0px;
            border-radius: 8px;
            text-align: center;
            display: inline-block;
            text-transform: uppercase;
            color: var(--white);
            background: var(--quaternary);

            i {
                margin-right: 5px;
            }
            
        }
        button:hover{
            background-color: var(--primary);
        }
    }
}

@media (max-width: 575px) {
    .news-form {
        flex-direction: column;
    }
}

@media (max-width: 767px) {
    .news-text {
        margin-bottom: 30px;
    }
}

@media (max-width: 991px) {
    .news-part {
        padding: 50px 30px 60px;

        .container-fluid {
            padding: 0px;
        }
    }
}