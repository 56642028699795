

.branch-card {
    margin: 0px 2.5px 30px;
    position: relative;

    &:hover {
        &::before {
            background: rgba(17, 151, 68, 0.85);
        }

        .branch-overlay {
            top: 50%;

            p {
                opacity: 1;
            }
        }
    }

    &::before {
        position: absolute;
        content: "";
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.45);
        @include cursor__transition;
    }

    img {
        width: 100%;
        border-radius: 8px;
    }
}

.branch-overlay {
    position: absolute;
    top: 60%;
    left: 0px;
    width: 100%;
    padding: 0px 15px;
    text-align: center;
    transform: translateY(-50%);
    @include cursor__transition;

    h3 {
        color: var(--white);
        letter-spacing: 0.3px;
        margin-bottom: 8px;
        text-transform: capitalize;
        text-shadow: var(--primary-tshadow);
    }

    p {
        width: 200px;
        margin: 0 auto;
        letter-spacing: 0.3px;
        color: var(--white);
        opacity: 0;
        text-transform: capitalize;
        text-shadow: var(--primary-tshadow);
        @include cursor__transition;
    }
}