





.order-track {
    margin-bottom: 25px;
}

.order-track-list {
    @include flex__center;
    padding: 25px 25px;
    border-radius: 8px;
    background: var(--chalk);
}

.order-track-item {
    @include flex__center;
    flex-direction: column;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 1;

    &::before {
        position: absolute;
        content: "";
        top: 20px;
        left: 0px;
        width: 100%;
        height: 5px;
        z-index: -1;
        background: var(--white);
    }

    &:first-child {
        &::before {
            border-radius: 50px 0px 0px 50px;
        }
    }

    &:last-child {
        &::before {
            border-radius: 0px 50px 50px 0px;
        }
    }

    i {
        width: 45px;
        height: 45px;
        font-size: 20px;
        line-height: 42px;
        border-radius: 50%;
        margin-bottom: 10px;
        text-align: center;
        display: inline-block;
        color: var(--primary);
        background: var(--white);
        border: 2px dotted var(--primary);
    }

    span {
        font-weight: 500;
        line-height: 20px;
        white-space: nowrap;
        color: var(--heading);
        text-transform: capitalize;
    }
}

.order-track-item.active {
    &::before {
        background: var(--primary);
    }

    i {
        color: var(--white);
        background: var(--primary);
    }

    span {
        color: var(--primary);
    }
}

@media (max-width: 575px) {
    .order-track {
        overflow-x: scroll;
    }

    .order-track-list {
        width: 575px;
    }
}

@media(min-width: 576px) and (max-width: 767px) {
    .order-track-item {
        span {
            white-space: inherit;
        }
    }
}