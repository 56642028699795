

.suggest-card {
    position: relative;

    &::before {
        position: absolute;
        content: "";
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.4);
        @include cursor__transition;
    }

    &:hover {
        &::before {
            background: rgba(17, 151, 68, 0.9);
        }
    }

    img {
        width: 100%;
        border-radius: 8px;
    }

    h5 {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        text-align: center;
        transform: translate(-50%, -50%);
        color: var(--white);
        font-weight: 400;
        text-transform: capitalize;
        text-shadow: var(--primary-tshadow);
        
        span {
            display: block;
            font-size: 15px;
            font-weight: 300;
        }
    }
}

@media (max-width: 400px) {
    .suggest-card {
        h5 {
            font-size: 16px;
            line-height: 24px;

            span {
                font: 14px;
            }
        }
    }
}