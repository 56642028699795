


//========================================
//       PRODUCT CARD ELEMENT STYLE
//========================================
.product-card {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 25px;
    padding: 15px 18px;
    border-radius: 8px;
    background: var(--white);
    border: 1px solid var(--white);
    @include cursor__transition;
    //height: 380px;

    &:hover {
        border-color: var(--primary);
        box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.1);
        
        .product-widget {
            opacity: 1;
            bottom: 15px;
        }

        .product-add {
            color: var(--white);
            background: var(--primary);
        }
    }
}
.product-button {
    vertical-align: middle;
    margin: auto;

  
}

.product-media {
    position: relative;
}

.product-label {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0px;
    left: 0px;
}

.label-text {
    font-size: 14px;
    padding: 5px 8px;
    line-height: 13px;
    border-radius: 3px;
    margin-bottom: 5px;
    color: var(--white);
    text-align: center;
    text-transform: capitalize;
}

.label-text.off {
    background: var(--red);
}

.label-text.new {
    background: var(--green);
}

.label-text.sale {
    background: var(--orange);
}

.label-text.feat {
    background: var(--purple);
}

.label-text.rate {
    background: var(--yellow);
}

.label-text.order {
    background: var(--blue);
}

.product-wish {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 18px;
    color: var(--primary);
    &:hover {
        color: var(--quaternary);
    }
}

.product-wish.active {
    color: var(--quaternary);
}

.product-image {
    img {
        width: 100%;
    }
}

.product-widget {
    @include flex__center;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    opacity: 0;
    @include cursor__transition;

    a {
        width: 40px;
        height: 40px;
        font-size: 15px;
        margin: 0px 6px;
        line-height: 40px;
        text-align: center;
        border-radius: 8px;
        color: var(--white);
        background: var(--quaternary);
        text-shadow: var(-primary-tshadow);
        box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.12);
        &:hover {
            color: var(--quaternary);
            background: var(--white);
       
        }
    }
}

.product-content {
    padding-top: 12px;
    text-align: center;
    border-top: 1px solid var(--border);
}

.product-rating {
    @include flex__center;
    margin-bottom: 3px;

    i,
    a {
        font-size: 14px;
        margin: 0px 2px;
        color: var(--gray);
    }

    a {
        white-space: nowrap;
        @include cursor__transition;

        &:hover {
            color: var(--primary);
            text-decoration: underline;
        }
    }

    .active {
        color: var(--yellow);
    }
}

.product-name {
    margin-bottom: 3px;
    text-transform: capitalize;
    height: 50px;
    /*white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;*/

    a {
        
        color: var(--sub-heading);
        @include cursor__transition;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        &:hover {
            color: var(--primary);
        }
    }
}

.product-price {
    @include flex__center;
    align-items: baseline;
    margin-bottom: 13px;

    del {
        color: var(--red);
        margin-right: 8px;
    }

    span {
        color: var(--tertiary);
        white-space: nowrap;

        small {
            font-weight: 400;
        }
    }
}


//========================================
//       PRODUCT DISABLE CARD STYLE
//========================================
.product-disable {
    position: relative;

    &:hover {
        border-color: var(--border);
        box-shadow: none;

        .product-add {
            color: var(--heading);
            background: var(--border);
        }
    }

    .product-widget {
        visibility: hidden;
    }

    &::before {
        position: absolute;
        content: "Out of Stock";
        top: 40%;
        left: 50%;
        z-index: 2;
        width: 100%;
        font-size: 15px;
        font-weight: 400;
        padding: 15px 0px;
        text-align: center;
        text-transform: uppercase;
        text-shadow: var(--primary-tshadow);
        transform: translate(-50%, -50%);
        color: var(--white);
        background: rgba(224, 152, 22, 0.9);
    }

    &::after {
        position: absolute;
        content: "";
        top: 0px;
        left: 0px;
        z-index: 1;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.6);
    }
}


//========================================
//      RESPONSIVE PRODUCT CARD STYLE
//========================================
@media (max-width: 575px) {
    .product-card {
        padding: 8px;
        margin-bottom: 8px;
    }

    .product-name {
        font-size: 15px;
        line-height: 22px;
    }
}
