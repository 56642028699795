

//========================================
//     CATEGORY SIDEBAR LAYOUT STYLE
//========================================
.category-sidebar {
    position: fixed;
    top: 0px;
    left: -350px;
    width: 320px;
    height: 100vh;
    z-index: 5;
    background: var(--white);
    box-shadow: 15px 0px 25px 0px rgba(0, 0, 0, 0.15);
    
    ::-webkit-scrollbar {
        width: 5px;
        background: #ffffff;
        fill-opacity: 0.1;
        position: relative;
        left: 10px;
      }
      
      ::-webkit-scrollbar-track {
        background: #a3fff0; 
        background: linear-gradient(180deg, rgba(37,207,199,0.3) 0%, rgba(43,199,200,0.3) 51%, rgba(255,255,255,0.3) 100%);
            margin-top:10px;
            margin-bottom:10px;
            border-radius: 5px;
            margin-right: 10px;
      }
      
      ::-webkit-scrollbar-thumb {
        border-radius: 100px;
        /*background-clip: content-box;*/
        background: #25cfc7;
            border-radius: 5px; 
            border: 5px solid transparent;
            margin-right: 10px;
      }
      
      ::-webkit-scrollbar-thumb:hover {
        background: #25dad1; 
      }
    
    
}

.category-sidebar.active {
    left: 0px;
    &::before {
        content: '';
        position: fixed;
        top: 0px;
        left: 350px;
        width: 750px;
        height: 100%;
        background: none;
        opacity: 0.9;
        z-index: 1;
    }

}

.category-header {
    padding: 15px 18px;
    position: relative;
    border-bottom: 1px solid var(--border);
    @include flex__center__start;
}

.category-title {
    color: var(--primary);
    text-transform: capitalize;
    @include flex__center__start;

    i {
        margin-right: 8px;
    }
}

.category-close {
    position: absolute;
    top: 50%;
    right: -18px;
    transform: translateY(-50%);

    i {
        width: 35px;
        height: 35px;
        font-size: 18px;
        line-height: 35px;
        border-radius: 50%;
        text-align: center;
        display: inline-block;
        color: var(--text);
        background: var(--white);
        text-shadow: var(--primary-tshadow);
        @include cursor__transition;

        &:hover {
            color: var(--white);
            background: var(--primary);
        }
    }
}

.category-list {
    width: 100%;
    padding: 0px 15px;
    overflow-y: scroll;
    height: calc(100vh - 130px);
  
   
}

.category-item {
    width: 100%;
    border-bottom: 1px solid var(--border);
     span {
        color: var(--text);
        background: var(--white);
        text-decoration: none;
     }
  
            
}

.category-link {
    font-size: 16px;
    font-weight: 400;
    padding: 12px 15px;
    color: var(--text);
    text-transform: capitalize;
    @include flex__center__start;
    @include cursor__transition;

    i {
        font-size: 25px;
        margin-right: 15px;
        display: inline-block;
    }
}

.category-footer {
    text-align: center;
    margin-top: 20px;

    p {
        font-size: 14px;
        color: var(--gray);

        a {
            color: var(--primary);
        }
    }
}
ul {
    list-style-type: none;
}