


// HEADER TOP
.header-top {
    padding: 2px 0px;
    background: var(--secondary);
}

// HEADER TOP WELCOME

// HEADER TOP SELECT
.header-select-contact {
    
    color: var(--white);
    padding: 5px;
    margin: auto;
    width: 49%;

}
       
.navbar-info a{ 
    margin-left: 25%;
    color: var(--white);
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    margin-left: 10px;
    
   
    
}
.header-left-img{
    word-wrap: break-word;
   
    label {
        vertical-align: middle;
        width: fit-content;
        font-size: 14px;
        color: var(--white);
        letter-spacing: 0.3px;

    }
   
}

.header-select {
    width: 50%;
    display: flex;
    align-items: space-evenly;
   
    img {
        margin-top: 0;
        width: 80px;
        height: 80px;
        margin-right: 10px;
    }

   

    &:last-child {
        border: none;
    }

    i {
        font-size: 16px;
        margin-right: 8px;
        color: var(--white);
    }

    .nice-select {
        line-height: 20px;

        &::after {
            border-right-color: var(--white);
            border-bottom-color: var(--white);
        }

        .current {
            color: var(--white);
        }
    }
}



//========================================
//      RESPONSIVE HEADER TOP STYLE
//========================================
@media (max-width: 990px) {
    .header-top-welcome,
    .header-top {
        display: none;
    }

    .header-top-select {
        width: 270px;
        margin: 0px auto;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .header-top-welcome {
        text-align: center;
        margin-bottom: 10px;
    }
}

 