/*========================================
            BODY PAGE STYLE
=========================================*/

textarea {
    resize: none;
}
body::-webkit-scrollbar {
  width: 5px;
  background: #ffffff;
  fill-opacity: 0.1;
  position: relative;
  left: 10px;
}

body::-webkit-scrollbar-track {
  background: #a3fff0; 
  background: linear-gradient(180deg, rgba(37,207,199,0.3) 0%, rgba(43,199,200,0.3) 51%, rgba(255,255,255,0.3) 100%);
      margin-top:10px;
      margin-bottom:10px;
      border-radius: 5px;
      margin-right: 10px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 100px;
  /*background-clip: content-box;*/
  background: #25cfc7;
      border-radius: 5px; 
      border: 5px solid transparent;
      margin-right: 10px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #25dad1; 
}

body{
  box-sizing: border-box;
}


/*========================================
            INDEX PAGE STYLE
=========================================*/
.container
{margin-right: auto;
  margin-left: auto}
.home-index-slider .dandik {
  right: 30px;
}
.cart-prewive-label{
  display: block;
  font-weight: bold;
  width: 100%;
  border-bottom: 2.5px solid #762ba5;
 
  
}


.testi-quote > p {

  font-size: 20px;
  line-height: 40px;
  margin-bottom: 20px;
  

}

.home-index-slider .bamdik {
  left: 30px;
}

.home-index-slider .slick-dots {
  bottom: 50px;
}



  .banner-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: -moz-fit-content;
         height: -moz-fit-content;
    width: fit-content;
    height: fit-content;
    object-fit: fill;
    background: #fff;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 15px 15px 10px rgba(0,0,0,0.9);
  }

.banner-part {
  padding: 50px 0px 60px;
 
  position: relative;
  z-index: 1;
}

.banner-part::before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.05;
  
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.banner-1 {
  /*background-color: linear-gradient(to left, #45f76354, #ffc3d024);*/

 
  background: url("../../public/images/icono-slider.svg") no-repeat center, linear-gradient(to right, #ffffff, #7aeacc);
  padding: 0px, 0px, 0px, 0px;
  margin-top: -1px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; 
  
}

.banner-2 {
  background: linear-gradient(to left, #45f76354, #ffc3d024);
}

.banner-content h1 {
  text-transform: capitalize;
  margin-bottom: 22px;
}

.banner-content p {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 50px;
}

.banner-btn .btn {
  margin-right: 15px;
}


.Footer-brand-icons img{
  width: 63px;
  height: 53px;
  object-fit: cover;
  margin-top: -15px;
  border-radius: 10px;
  padding: 5px;
  

  

}

@media (max-width: 767px) {
  .banner-content h1 {
    font-size: 38px;
    line-height: 48px;
  }
 
  
  .banner-img{

    display: block;
   ;}

 
  .banner-btn .btn {
    margin: 8px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .banner-content h1 {
    font-size: 32px;
    line-height: 42px;
  }
  .banner-btn .btn {
    padding: 14px 22px;
    margin-right: 10px;
  }
  .testi-quote > p {

    font-size: 14px;
    line-height: 30px;
    margin-bottom: 20px;
    
  
  }
}

.suggest-slider li {
  margin: 0px 10px;
}

@media (max-width: 575px) {
  .suggest-slider li {
    margin: 0px 5px;
  }
}

.promo-img {
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
}

.promo-img a {
  width: 100%;
}

.promo-img a img {
  width: 100%;
  transition: all linear .3s;
  border-radius: 8px;
  -o-transition:all linear .3s; 
  -webkit-transition: all linear .3s;
  -moz-transition: all linear .3s;
  -ms-transition: all linear .3s;
 
}

.promo-img a img:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

@media (max-width: 767px) {
  .promo-img {
    margin: 12px 0px;
  }
}

.new-slider li {
  margin: 0px 12px;
}

@media (max-width: 575px) {
  .new-slider .product-card {
    width: 220px;
  }
}



@media (max-width: 767px) {
  .blog-slider .blog-card {
    margin: 0px 10px 25px;
  }
}



.Footer-brand-icons a{
  margin-top: -15px;
  color:var(--white);
  font-size: 50px;
  padding: 5px;

  text-decoration: none;
}


.logo-mace-footer{
  
  margin-left: 40px;
  margin-top: 5px;
}

@media  (max-width: 768px) {
  .Footer-brand-icons a{
    margin-top: -15px;
    color:var(--white);
    font-size: 50px;
    padding: 5px;
  
    text-decoration: none;
  }
 
}

.testi-img > img {
  width: 100%;
  border-radius: 50%;
  object-fit: cover;


}
.team-media {
  position: relative;
  overflow: hidden;
  border-radius: 25%;
  margin-bottom: 20px;
}
.team-slider{
  margin-top: 20px;
  width: 500px;
  margin: auto;


}
.team-media > img {
  object-fit: contain;
 
  height: 300px;
 
 
}




 