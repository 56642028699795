.conten {

    margin-right: 23%;
    margin-left: 23%;
    margin-top: 1%;
    margin-bottom: 3%;
}
.header-h{
    margin-top: -5px;
    margin-bottom: 1%;
    font-weight: bold;
  
}
.rating{ 
    margin-top: 1%;
    margin-bottom: 1%;
    font-weight: bold;
    display: flex;
}