


//========================================
//          NAVBAR LAYOUT STYLE
//========================================
.navbar-part {
    background: linear-gradient(to right, rgb(53, 102, 89), rgb(118, 234, 189));
    //height: 40px;
    padding: 10px 0px;

}

.navbar-content {
    //border-top: 1px solid var(--border);
    @include flex__center__between;
    margin-left: 11%;
}

.navbar-list {
    @include flex__center__start;
}

.navbar-item {
    margin-right: 30px;

    &:last-child {
        margin-right: 0px;
    }
}

.navbar-link {
    //padding: 17px 0px;
    font-weight: 500;
    color: var(--white);
    text-transform: capitalize;
    @include cursor__transition;

    &:hover {
        color: var(--secondary);
    }
}

.navbar-focus-list {
    @include flex__center__end;

    li {
        margin-left: 30px;

        &:first-child {
            margin-left: 0px;
        }

        a {
            font-weight: 500;
            color: var(--text);
            text-transform: capitalize;
            @include cursor__transition;

            &:hover {
                color: var(--primary);
            }

            i {
                font-size: 18px;
                margin-right: 5px;
            }
        }
    }
}


.navbar-info-group {
    @include flex__center;
}

.navbar-info {
    margin-right: 20px;
    text-align: right;

    &:last-child {
        margin-right: 0px;
    }

    i {
        font-size: 30px;
        margin-right: 15px;
        color: var(--white);
    }

    p {
        small {
            font-size: 14px;
            line-height: 16px;
            display: block;
            text-align: left;
            text-transform: capitalize;
        }

        span {
            font-size: 15px;
            font-weight: 500;
        }
    }
}

//========================================
//    RESPONSIVE NAVBAR LAYOUT STYLE
//========================================
@media (max-width: 991px) {
    .navbar-part {
        display: none;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .navbar-list {
        li {
            margin-right: 18px;
        }
    }

    .navbar-link {
        font-size: 15px;
    }

    .navbar-info {
        margin-right: 15px;

        p {
            span {
                font-size: 14px;
            }
        }

        i {
            margin-right: 10px;
        }
    }
}
