

//========================================
//          HEADER LAYOUT STYLE
//========================================

.menu-button { 
    display: none;
    position: fixed;
    bottom: 15%;
    right: -1200px;
    transform: translateY(-50%);
    z-index: 3;
    @include flex__center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--white);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    i {
        font-size: 20px;
        color: var(--primary);
        text-shadow: 2px 3px 8px rgba(0, 0, 0, 0.1);
    }

    &:hover {
        background: var(--chalk);
    }
}
.header-part {
    
    background: var(--primary);
    height: 75px;
    ::-webkit-scrollbar {
        width: 5px;
        background: #ffffff;
        fill-opacity: 0.1;
        position: relative;
        left: 10px;
      }
      
      ::-webkit-scrollbar-track {
        background: #a3fff0; 
        background: linear-gradient(180deg, rgba(37,207,199,0.3) 0%, rgba(43,199,200,0.3) 51%, rgba(255,255,255,0.3) 100%);
            margin-top:10px;
            margin-bottom:10px;
            border-radius: 5px;
            margin-right: 10px;
      }
      
      ::-webkit-scrollbar-thumb {
        border-radius: 100px;
        /*background-clip: content-box;*/
        background: #25cfc7;
            border-radius: 5px; 
            border: 5px solid transparent;
            margin-right: 10px;
      }
      
      ::-webkit-scrollbar-thumb:hover {
        background: #25dad1; 
      }
    .notifications {
    
    max-height: 75vh;
    min-height: 20vh;
    overflow-y: scroll;   
    border-radius: 8px;
    border: 1px solid var(--border);
    box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.6);
    position:fixed;
    z-index: 2;
    top: 85px;
    right: 3%;
    background-color: var(--chalk);
    color: var(--text);
    font-weight: 300;
    padding-top: 15px;

    max-width: 20%;
     
    &::before {
        position: fixed;
        content: "";
        top: 75px;
        right: calc(15% + 10px);
        width: 25px;
        height: 25px;
        border-radius: 3px;
        transform: rotate(45deg);
        background: var(--chalk);
        border-top: 1px solid var(--border);
        border-left: 1px solid var(--border);
}
        .notification {
            
            cursor: pointer;
            margin: auto;
            width: 75%;
            padding-bottom: 18px;
            margin-bottom: 20px;
            position: relative;
            text-transform: capitalize;
            border-top: 1px solid var(--border);
        
            &::before {
                position: absolute;
                content: "";
                top: -2px;
                left: 0px;
                width: 150px;
                height: 2px;
                background: var(--primary);
            }
          padding: 5px;
          font-size: 14px;
          .nButton {
            margin-left: 5%;
        font-size: 12px;
        width: 75%;
        padding: 5px;
        margin-top: 3px;
      }
        }
      }
    
    .header-logo {
        width: 65px;
        padding: auto;
        position: absolute;
    top: 10px;
    left: 10px;
    

        img {
             width: 65px;
             display: flex;
    align-items: center; /* vertical */
    justify-content: center; /* horizontal */
          
        }
         
      
    }
   
}



.sticky{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 3;
    background: var(--primary);
    height: 75px;
    border-radius: 0px 0px 5px 5px;

    .header-logo {
        margin-top : 0px;
        margin-left: 2%;
        padding: auto;
       
        width: 6vw;
        z-index: 3;
       
        img {
            width: 55px;
        }
         
    

}
    .header-content {
        padding: 5px 0px;
     
    }
   
        
    .header-widget {
        &:hover {
            i {
                background: var(--primary);
            }
        }
        
        sup {
            border-color: var(--white);
        }
    }

    .header-form {
        
        background: var(--white);
        border-color: var(--primary);
       
    }
     .header-part {
        height: 75px;
        
    }
}

.searchy{
   
   position: fixed;
   
    background: var(--white);
    z-index: 3;
   
 
     

    
}
.header-content {
    padding: 8px 0px;
    @include flex__center;
   
}


.header-widget-group {
    @include flex__center;
    position: absolute;
    right: 0px;
    top: 15px;

    .header-widget {
        margin-right: 8px;

        &:first-child {
            margin-left: 0px;
        }
    }
}

.header-widget {
    position: relative;
    @include flex__center;

    &:hover {
        i {
            color: var(--white);
            background: var(--tertiary);
            text-shadow: var(--primary-tshadow);
        }

        span {
            color: var(--white);
            text-shadow: var(--black);
        }
    }

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    i {
        width: 40px;
        height: 40px;
        font-size: 15px;
        line-height: 40px;
        text-align: center;
        display: inline-block;
        border-radius: 50%;
        color: var(--text);
        background: var(--white);
       
    }

    span {
        font-size: 15px;
        font-weight: 400;
        margin-left: 8px;
        letter-spacing: 0.3px;
        white-space: nowrap;
        text-align: left;
        text-transform: capitalize;
        color: var(--white);
       

        small {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            font-family: sans-serif;
            display: block;
        }
    }

    sup {
        position: absolute;
        top: -12px;
        left: 20px;
        width: 24px;
        height: 24px;
        font-size: 12px;
        line-height: 20px;
        border-radius: 50%;
        text-align: center;
        color: var(--white);
        background: var(--quaternary);
        border: 2px solid var(--white);
        text-shadow: var(--primary-tshadow);
    }
}

.header-cart {
    span {
        font-size: 12px;
        margin-left: 15px;
        line-height: 20px;
        text-transform: uppercase;
    }
}

.header-form {
    width: 66%;
    //margin: 0px 50px;
    border-radius: 8px;
    background: var(--chalk);
    border: 2px solid var(--chalk);
    @include flex__center;
    position: absolute;
    top: 10px;
    left: 125px;

    &:focus-within {
        background: var(--white);
        border-color: var(--quaternary);
    }

    input {
        width: 100%;
        height: 45px;
        font-size: 15px;
        padding-left: 15px;
    }

    button {
        i {
            width: 45px;
            height: 45px;
            font-size: 15px;
            line-height: 45px;
            text-align: center;
            border-radius: 8px;
            color: var(--text);
            display: inline-block;
           

            &:hover {
                color: var(--primary);
            }
        }
    }
}

.header-media-group {
    display: none;
    align-items: center;
    justify-content: space-between;

    a {
        img {
            width: auto;
            height: 45px;
        }
    }
}

.header-user,
.header-src {
    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    i {
        width: 40px;
        height: 40px;
        font-size: 15px;
        line-height: 40px;
        text-align: center;
        display: inline-block;
        border-radius: 50%;
        color: var(--text);
        background: var(--chalk);
       

        &:hover {
            color: var(--white);
            background: var(--tertiary);
        }
    }
}

//========================================
//        RESPONSIVE HEADER STYLE
//========================================
@media (max-width: 1660px) {
    .header-form {
        width: 55%;
    }
    .header-part {
    .notifications {
        
        max-width: 22%;
        &::before {
            right: calc(19% + 5px);
        }
    }
    }
}
@media (max-width: 1300px) {
.header-form {
    width: 50%;
}
.header-part {
    .notifications {
        max-width: 30%;
        &::before {
            right: calc(22% + 5px);
        }
     
    }
}

}
@media (min-width: 992px) and (max-width: 1199px) {
    .header-widget {
        span {
            display: none;
        }
    }
    .header-part {
        .notifications {
            max-width: 40%;
            &::before {
                right: calc(20% + 10px);
            }
        }
    }
}
@media (max-width: 991px) {

    .header-logo {
        margin-top : 1%;
        margin-left: 2%;
        padding: auto;
        
        width: 10vw;
        z-index: 3;
        
        img {
            width: 100%;
          
        }
         
    

}

.menu-button { 
    
    right: 2px;
}
    .header-part {
        height: 75px;
        .notifications {
            margin-top: 9px;
            max-width: 40%;
            &::before {
                top: 85px;
                right: calc(15% + 10px);
            }
        }
    }

    .header-content {
        padding: 10px 0px;
        flex-direction: column;
    }

    .header-media-group {
        width: 100%;
        display: flex;
         
        
            img {
                width: 100%;
                height: 45px;
            }
        }
    

    .header-widget-group,
    .header-widget,
    .header-logo {
        display: none;
        span {
            display: none;
        }
    }

    .header-form {
        display: none;
        margin: 0px 0px 0px;
    }

    .header-form.active {
        margin-top: 80px;
        display: flex;
        border: 2px solid var(--chalk);
        z-index: 3;
    
    }
}


@media (max-width: 750px) {
    .header-part {
    .notifications {
        
        max-width: 90%;
        &::before {
            right: calc(15% + 5px);
        }
    }
}
}