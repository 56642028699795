


//========================================
//          COUNTDOWN PART STYLE
//========================================
.countdown-part {
    padding: 80px 0px;
    background: linear-gradient(to left, #c3ffda, #a1f3c0);
}

.countdown-content {
    text-align: center;

    h3 {
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
        text-transform: uppercase;
        margin-bottom: 12px;
    }

    p {
        margin-bottom: 38px;
    }
}

.countdown-clock{
    margin-bottom: 45px;
}

.countdown-img {
    position: relative;

    img {
        width: 100%;
    }
}

.countdown-off {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    padding: 35px 30px 30px;
    background: var(--red);
    position: absolute;
    top: 0px;
    left: 0px;

    span {
        display: block;
        text-transform: uppercase;
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 0.5px;
        color: var(--white);
    }
}

@media (max-width: 767px) {
    .countdown-part {
        padding: 60px 0px;
    }

    .countdown-img {
        margin-top: 30px;
    }
}