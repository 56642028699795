

.table-list {
    width: 100%;
}


thead {
    tr {
        background: var(--tertiary);

        th {
            font-size: 17px;
            font-weight: 500;
            white-space: nowrap;
            text-align: center;
            text-transform: capitalize;
            padding: 12px 20px;
            color: var(--white);
            border-right: 1px solid var(--border);

            &:first-child {
                border-radius: 6px 0px 0px 6px;
            }

            &:last-child {
                border-right: none;
                border-radius: 0px 6px 6px 0px;
            }
        }
    }
}


tbody {
    padding: 5px;
    
    tr {
        
        border-bottom: 5px solid var(--border);

        td {
            
            padding: 12px 20px;
            text-align: center;
            border-right: 5px solid var(--border);

            &:last-child {
                border-right: none;
            }
        }
    }
}

.table-serial {
    width: 10%;

}
.table-name {
    width: 15%; 
    line-clamp: 2;
   
    text-transform: capitalize;
    -webkit-box-orient: vertical;
    
}

.table-image {
    img {
        width: 100px;
        height: 100px;
    }
}

.table-price {
    word-wrap: initial;
    h6 {
        font-size: 17px;

        small {
            font-size: 13px;
            margin-left: 3px;
            color: var(--gray);
            font-family: sans-serif;
        }
    }
}

.table-desc {
    p {
        width: 150px;
        font-size: 15px;
        text-align: left;

        a {
            text-transform: capitalize;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.table-vendor {
    a {
        font-weight: 500;
        color: var(--heading);
        text-transform: capitalize;
        @include cursor__transition;

        &:hover {
            color: var(--primary);
        }
    }
}

.table-status {
    h6 {
        text-transform: capitalize;
        text-align: center;
    }

    .stock-in {
        color: var(--purple);
        text-align: center;
    }
    
    .stock-out {
        color: var(--orange); 
        text-align: center;
    }
}

.table-shop {
    

    .product-add {
        color: var(--white);
        background: var(--primary);
        width: 5vw;
        margin: auto;
       

    }

}

.table-action {
    
    a {
      
        i {
            width: 35px;
            height: 35px;
            font-size: 16px;
            line-height: 35px;
            text-align: center;
            display: inline-block;
            border-radius: 5px;
            margin: 3px;
            @include cursor__transition;
        }
    }

    .view {  
        i { margin: auto;
            color: var(--green);
            background: var(--white);

            &:hover {
                color: var(--white);
                background: var(--green);
            }
        }
    }

    .wish {  
        i { margin: auto;
            color: var(--green);
            background: var(--white);
        }
    }

    .wish.active {
        i {
            color: var(--white);
            background: var(--green);
        }
    }

    .trash {  margin: auto;
        i { margin: auto;
            color: var(--red);
            background: var(--white);

            &:hover {
                color: var(--white);
                background: var(--red);
            }
        }
    }
}

@media (max-width: 1199px) {
    .table-scroll {
        overflow-x: scroll;
    }
}