/*========================================
          BLOG-DETAILS PAGE STYLE
=========================================*/
.blog-details-thumb img {
  width: 1050px;
  border-radius: 10px;
  height: 250px;
  margin: auto;

}

.blog-details-content {
  padding: 50px;
  margin-bottom: 30px;
  background: var(--white);
  border-radius: 0px 0px 10px 10px;
}

.blog-details-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 20px;
}

.blog-details-meta li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 40px;
}

.blog-details-meta li:last-child {
  margin: 0px;
}

.blog-details-meta li i {
  color: var(--primary);
  margin-right: 10px;
  font-size: 16px;
}

.blog-details-meta li a,
.blog-details-meta li span {
  font-size: 16px;
  white-space: nowrap;
  text-transform: uppercase;
}

.blog-details-meta li a {
  color: var(--text);
  transition: all linear .3s;
  -webkit-transition: all linear .3s;
  -moz-transition: all linear .3s;
  -ms-transition: all linear .3s;
  -o-transition: all linear .3s;
}

.blog-details-meta li a:hover {
  color: var(--primary);
}

.blog-details-title {
  line-height: 44px;
  margin-bottom: 25px;
}

.blog-details-desc {
  font-size: 17px;
  line-height: 30px;
  margin-bottom: 20px;
}

.blog-details-quote {
  padding: 60px 60px 60px 180px;
  border-radius: 10px;
  margin-bottom: 20px;
  background: var(--heading);
  position: relative;
}

.blog-details-quote::before {
  position: absolute;
  content: "\2728";
  left: 60px;
  top: 60px;
  font-size: 60px;
  font-family: "icofont";
  color: var(--primary);
}

.blog-details-quote p {
  font-size: 28px;
  line-height: 1.4;
  margin-bottom: 20px;
  color: var(--white);
}

.blog-details-quote footer {
  letter-spacing: 3px;
  color: var(--green-chalk);
  text-transform: uppercase;
}

.blog-details-grid {
  margin-bottom: 30px;
}

.blog-details-grid img {
  width: 1050px;
  border-radius: 10px;
  height: 550px;
  margin: auto;
  opacity: 0.5;
}

.blog-details-grid p {
  font-size: 17px;
  line-height: 30px;
}

.blog-details-subtitle {
  margin-bottom: 30px;
}

.blog-details-subtitle h3 {
  margin-bottom: 10px;
}

.blog-details-subtitle p {
  font-size: 17px;
  line-height: 30px;
}

.blog-details-list {
  list-style-type: decimal;
  margin: 0px 30px 0px 50px;
}

.blog-details-list li {
  margin-bottom: 25px;
}

.blog-details-footer {
  padding-top: 45px;
  margin-top: 50px;
  border-top: 1px solid var(--border);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.blog-details-share li span {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: capitalize;
  display: block;
}

.blog-details-share li {
  margin-right: 8px;
  display: inline-block;
}

.blog-details-share li a {
  width: 40px;
  height: 40px;
  font-size: 15px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  color: var(--text);
  background: var(--chalk);
  text-transform: capitalize;
  transition: all linear .3s;
  -webkit-transition: all linear .3s;
  -moz-transition: all linear .3s;
  -ms-transition: all linear .3s;
  -o-transition: all linear .3s;
}

.blog-details-share li a:hover {
  color: var(--white);
  background: var(--primary);
}

.blog-details-tag li span {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: capitalize;
  display: block;
}

.blog-details-tag li {
  margin-right: 8px;
  display: inline-block;
}

.blog-details-tag li a {
  font-size: 14px;
  padding: 3px 12px;
  border-radius: 3px;
  color: var(--text);
  background: var(--chalk);
  text-transform: capitalize;
  transition: all linear .3s;
  -webkit-transition: all linear .3s;
  -moz-transition: all linear .3s;
  -ms-transition: all linear .3s;
  -o-transition: all linear .3s;
}

.blog-details-tag li a:hover {
  color: var(--white);
  background: var(--primary);
}

.blog-details-profile {
  padding: 50px;
  border-radius: 10px;
  margin-bottom: 30px;
  background: var(--white);
  text-align: center;
}

.blog-details-profile a img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
  border: 8px solid var(--chalk);
}

.blog-details-profile h3 {
  text-transform: capitalize;
}

.blog-details-profile h4 {
  font-size: 15px;
  font-weight: 400;
  color: var(--primary);
  margin-bottom: 15px;
}

.blog-details-profile ul {
  margin-bottom: 25px;
}

.blog-details-profile ul li {
  display: inline-block;
  margin: 0px 5px;
}

.blog-details-profile ul li a {
  width: 40px;
  height: 40px;
  font-size: 15px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  color: var(--text);
  background: var(--chalk);
  text-transform: capitalize;
  transition: all linear .3s;
  -webkit-transition: all linear .3s;
  -moz-transition: all linear .3s;
  -ms-transition: all linear .3s;
  -o-transition: all linear .3s;
}

.blog-details-profile ul li a:hover {
  color: var(--white);
  background: var(--primary);
}

.blog-details-profile p {
  font-size: 17px;
  line-height: 30px;
}

.image-shadow {
  margin-top: 5px;
  background-color: black;
  opacity: 1;
  color: #fff;
  border-radius: 8px;
}


@media (max-width: 575px) {
  .blog-details-content {
    padding: 20px;
  }
  .blog-details-title {
    font-size: 28px;
    line-height: 38px;
  }
  .blog-details-quote {
    padding: 75px 25px 25px;
  }
  .blog-details-quote::before {
    font-size: 50px;
    left: 25px;
    top: 25px;
  }
  .blog-details-quote p {
    font-size: 22px;
  }
  .blog-details-list {
    margin: 0px 15px 0px 35px;
  }
  .blog-details-footer {
    margin-top: 0px;
    padding-top: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .blog-details-share {
    margin-bottom: 30px;
  }
  .blog-details-navigate li a {
    padding: 13px 13px;
  }
  .blog-details-profile {
    padding: 25px 15px;
  }
  .blog-details-comment {
    padding: 15px;
  }
  .comment-item {
    padding: 20px 20px;
  }
  .comment-reply {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .comment-reply input {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .comment-reply-list {
    margin-left: 15px;
  }
  .blog-details-form {
    padding: 20px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .blog-details-quote {
    padding: 120px 60px 60px;
  }
  .blog-details-footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .blog-details-share {
    margin-bottom: 30px;
  }
  .comment-reply-list {
    margin-left: 30px;
  }
}
