

//========================================
//          CATEGORY WRAP STYLE
//========================================
.category-wrap {
    &:hover {
        .category-overlay {
            transform: scale(1);
        }
    }
}

.category-media {
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;

    img {
        
        width: 100%;
        height: auto;
        border-radius: 8px;
    }
    a {
      
        opacity: 0.8;
       

        
        i {
            margin-top: 70%;
            margin-left: 66%;
            font-size: 65px;
            line-height: 50px;
            text-align: right;
            color: var(--white);
           
            @include cursor__transition;

            &:hover {
                color: var(--primary);
               
            }
        }
    }
}

.category-overlay {
    
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    text-align: center;
    background: rgba(0, 0, 0, 0.1);
    transform: scale(0);
    @include flex__center;
    @include cursor__transition;


}

.category-meta {
    padding: 0px 10px;

    h4 {
        text-transform: capitalize;
        margin-bottom: 3px;
    }
}

@media (max-width: 575px) {
    .category-wrap {
        width: 150px;
    }
}

@media (max-width: 1199px) {
    .category-meta {
        padding: 0px 5px;

        h4 {
            font-size: 17px;
            margin-bottom: 0px;
        }

        p {
            font-size: 15px;
        }
    }
}