



//========================================
//     BOTTOM PAGINATE LAYOUT STYLE
//========================================
.bottom-paginate {
    padding-top: 25px;
    border-top: 1px solid var(--border);
    @include flex__center__between;
}

.page-info {
    font-weight: 400;
    color: var(--text);
}

@media (max-width: 575px) {
    .bottom-paginate {
        margin-bottom: 30px;
        flex-direction: column;
    }

    .page-info {
        margin-bottom: 15px;
    }
}

@media (min-width: 576px) and (max-width: 991px) {
    .bottom-paginate {
        margin-bottom: 30px;
    }
}