


//========================================
//          ALL DECLARE VARIABLE
//========================================
:root {
    // FONT SIZE
    --h1size: 50px;
    --h2size: 40px;
    --h3size: 24px;
    --h4size: 20px;
    --h5size: 18px;
    --h6size: 16px;
    --bodysize: 16px;

    // LINE HEIGHT
    --h1height: 58px;
    --h2height: 48px;
    --h3height: 32px;
    --h4height: 28px;
    --h5height: 26px;
    --h6height: 26px;
    --bodyheight: 26px;

    // FONT FAMILY
    --pfamily: 'Rubik', sans-serif;

    // COLOR
    --red: #ff3838;
    --gray: #777777;
    --text: #555555;
    --blue: #1494a9;
    --white: #ffffff;
    --black: #000000;

    --chalk: #f5f5f5;
    --green: rgb(30, 182, 106);

    --purple: #b12fad;
    --orange: #e86121;
    --yellow: #ffab10;


    --body: #f5f6f7;
    --border: #e8e8e8;
    --heading: #39404a;

    //------------- T E M A 1
   --primary: #32e6b5;
   --secondary: #146647;
   --tertiary: #27b38d;
   --quaternary: #ff6666;

   --bs-link-color: #1b5142;

    //------------- T E M A 2
    //--primary: #66CC33;

  

    --sub-heading: #565765;
    --sub-heading: #565765;

    //--green-chalk: #FF9966;
    --green-chalk: #FF9966;
    --green-dark: #336699;

    //--gray-chalk: #FF9966; 1st
    --gray-chalk: #336699;
    --intro-bg: #f8fffa;

    // SOCIAL COLOR
    --facebook: #3b5998;
    --linkedin: #0e76a8;
    --twitter: #00acee;
    --google: #E60023;
    --instagram: #F77737;

    // BOX SHADOW
    --primary-bshadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.1);

    // TEXT SHADOW
    --primary-tshadow: 2px 3px 8px rgba(0, 0, 0, 0.1);
}


