/*========================================
            WISHLIST PAGE STYLE
=========================================*/
@media (max-width: 1199px) {
  .wishlist-part .container {
    max-width: 100%;
  }
}


.margen-personalizado{
  padding-bottom: 5%;
}